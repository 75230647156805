
import { useEffect, useState, useContext } from 'react';
import {ButtonSide, GlobalContainer, Header, Sidebar, ContainerGradient, BtnStrategySelect, Table, Th, Tr, Td, ContainerMobileHide, ContainerMobileShow, HeaderMobile, SideBarMobile, ButtomMenu, LogoMobile, ContainerMobileOverflow, BoxChampionshipHeader}  from './style';
import { BsList, BsSpeedometer2, BsGraphUp, BsPlayCircle, BsXLg, BsTags } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, Text, ImageLogo } from '../../componets/styles';
import { MainContainer } from './style';
import { BoxChampionship } from './style';
import { BoxDropDown } from './style';
import { BtnChanpionship } from './style';
import { ImgChanpionship } from './style';
import Reload from '../../componets/reload';
import ModalOdds from '../Modals/ModalOdds';
import { onHandleStrategy, onHandleGames } from './functions';
import { CHAMPIONS_SHIP, STRATEGY} from './initialStates';
import { formatarData, onLimitCharacters } from '../../share/functions';
import AuthContext from '../../context/AuthContext';

export default function Confrontations({handlePage})
{
    const navigate = useNavigate();

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const [isSideOpen, setIsSideOpen] = useState(false);

    const [isBoxDropDown, setIsBoxDropDown] = useState(false);

    const [championsShipSelect, setChampionsShipSelect] = useState(CHAMPIONS_SHIP[0])

    const [reload, setReload] = useState(false);

    const [modalOdds, setModalOdds] = useState(false);

    const [gameOddsSelect, setGameOddsSelect] = useState(null);

    const [dateOddsSelect, setDateOddsSelect] = useState(null);

    const [strategyMenu, setStrategyMenu] = useState(STRATEGY);

    const [strategySelect, setStrategySelect] = useState({id:34, name: 'Mais venceu'});

    const [isBoxDropDownStrategy, setIsBoxDropDownStrategy] = useState(false);

    const [rankingList, setRankingList] = useState([]);

    const [sideBarMobile, setSideBarMobile] = useState(false);

  
    useEffect(()=>{
        onStartGames();
    },[])

    async function onStartGames()
    {
        setReload(true);
        const teamsSort = await onHandleGames();
        setRankingList(teamsSort);
        setReload(false);
    }

    async function onActionChangeStrategy(item)
    {
        setReload(true);
        setStrategySelect(item)
        setIsBoxDropDownStrategy(false);
        const teamsSort = await onHandleStrategy(item, championsShipSelect.name)
        setRankingList(teamsSort);
        setReload(false);
    }

    async function onActiveChangeChampionsShip(item)
    {
        console.log(item)
        setReload(true);
        setChampionsShipSelect(item)
        const teamsSort = await onHandleStrategy(strategySelect, item.name);
        setRankingList(teamsSort);
        setIsBoxDropDown(false);
        setReload(false);
    }

    function visibleDrop() {
        setIsBoxDropDown(prev => !prev);
    }


    return(
        <GlobalContainer>
           {reload === true && <Reload/>} 

           <ContainerMobileHide>


                <Header>

                <Container display={'flex'}  items="center"  w="50%" h="100%" ml="50px">

                    <ImageLogo src='./images/logo.png'/>

                    <BoxChampionshipHeader onClick={visibleDrop}>
                        <Container display={'flex'}  items="center" jc="center">
                            <ImgChanpionship src={championsShipSelect.img} />
                            <Text fw="bold" fs="20px" col='#fff'>{onLimitCharacters(championsShipSelect.name, 15)}</Text>
                        </Container>
                    </BoxChampionshipHeader>


                    <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                        {
                            CHAMPIONS_SHIP.map(item => (
                                isBoxDropDown &&
                                <Container key={item.id} mt={item.margin}>
                                    <BtnChanpionship onClick={() => onActiveChangeChampionsShip(item)}>
                                        <ImgChanpionship src={item.img} />
                                        {item.name}
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }

                        <Container mt='20px'></Container>
                    </BoxDropDown>

                </Container>

                <Container w='auto' display='flex' mr="20px" items='center'>
                        <Container w='auto' display='flex' mr="20px" >
                            <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{`${formatarData(user.validation)}`}</Text>
                        </Container>


                        <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                            <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                        </ButtonStyled>
                    </Container>

                </Header>

                <Sidebar open={isSideOpen}>

                    <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                        <BsList col='#fff' size={45}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                    </ButtonSide>

{/** 
                        <ButtonSide open={isSideOpen}  mt="50px" onClick={()=>{navigate("/home")}}>
                            <BsSpeedometer2 col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                            
                        </ButtonSide>
*/}

                    <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/analysis")}}>
                        <BsGraphUp col='#fff' size={33}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                    </ButtonSide>

                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/videos')}}>
                        <BsPlayCircle  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                    </ButtonSide>

                    {/**
                     * 
                     *                     <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/offers')}}>
                        <BsTags  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px" >Ofertas</Text>}
                    </ButtonSide>
                     * 
                     * 
                     */}



                </Sidebar>

                <MainContainer >

                    <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display={"flex"} jc="space-between">
                        
                        <ButtonStyled w="16.2%" bg='#15803d' onClick={()=>{handlePage("maximims")}}>
                            <Text col="#fff" fw="bold" fs="25px">MÁXIMAS</Text>
                        </ButtonStyled>
  {/** 
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("expressCup")}}>
                            <Text col="#fff" fw="bold" fs="25px">EXPRESS CUP</Text>
                        </ButtonStyled>
  */}
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("hour")}}>
                            <Text col="#fff" fw="bold" fs="25px">HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("multihour")}}>
                            <Text col="#fff" fw="bold" fs="25px">MULTI HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("confrontations")}}>
                            <Text col="#fff" fw="bold" fs="25px">CONFRONTOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%" onClick={()=>{handlePage("ranking")}}>
                            <Text col="#fff" fw="bold" fs="25px">RANKING</Text>
                        </ButtonStyled>                     

                    </Container>

                    <Container w="98%" pl="20px" pr="20px">
                        <ContainerGradient>

                            <BtnStrategySelect onClick={()=>{setIsBoxDropDownStrategy(!isBoxDropDownStrategy)}} ml="50px">
                                <Text col='#fff'>{onLimitCharacters(strategySelect.name, 15)}</Text>
                            </BtnStrategySelect>

                            <BoxDropDown dp={isBoxDropDownStrategy === true ? '350px' : '0px'} >
                            {
                                strategyMenu.map((item, index )=> (
                                    isBoxDropDownStrategy &&
                                    <Container key={item.id} mt={"10px"} >
                                        {item.type === "text"
                                        ?
                                        <Container w="100%" display="flex" jc="center" items="center">
                                            <Text fw="bold" col="blue">{item.name}</Text>
                                        </Container>
                                        :

                                        <BtnChanpionship onClick={() => onActionChangeStrategy(item)}>
                                            <Text fw="bold" col="#000">{item.name}</Text>
                                        </BtnChanpionship>
                                    }
                                    </Container>
                                    ))
                            }
                                <Container mt="20px"></Container>
                            </BoxDropDown> 

                            <Container w="80%" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="30px">Resultado das últimas 24 horas</Text>
                            </Container>
                            
                        </ContainerGradient>
                    </Container>

                    <Container w="98%" pl="20px" pr="20px">
                        <Table>
                            <thead>
                            <Tr>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Posição</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Total de pontos</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Equipe</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Número de jogos</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Vitórias</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Empates</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">Derrotas</Text></Th>
                                <Th><Text col="#fff" fs="20px"  fw="bold">% de venceu</Text></Th>
                            </Tr>
                            </thead>
                            <tbody>
                            {rankingList.map((item, index)=>{
                                return (
                                        <Tr key={index}>
                                            <Td><Text col="#fff" fs="20px">{index + 1}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.pontos}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.equipe}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.jogos}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.vitorias}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.empates}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.derrotas}</Text></Td>
                                            <Td><Text col="#fff" fs="20px">{item.percentual_vitorias}</Text></Td>
                                        </Tr>
                                )
                            })}
                            </tbody>


                        </Table>
                        <Container mt="20px"></Container>
                    </Container>
        
                
                </MainContainer>

            </ContainerMobileHide>

            {/**TEMPLATE MOBILE */}
            <ContainerMobileShow>

                <HeaderMobile>

                    <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                        <BsList color='#fff' size={30}/>
                    </ButtonStyled>

                    <Container w='auto' display='flex' >
                        <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                    </Container>

                    <LogoMobile src='./images/logo.png'/>

                </HeaderMobile>

                <Container display={'flex'}  items="center" mt='20px'  w="95%" h="100%" ml="2.5%">

                    <BoxChampionship onClick={visibleDrop}>
                        <Container display={'flex'}  items="center" jc="center">
                            <ImgChanpionship src={championsShipSelect.img} />
                            <Text fw="bold" fs="20px" col='#fff'>{onLimitCharacters(championsShipSelect.name, 15)}</Text>
                        </Container>
                    </BoxChampionship>


                    <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                        {
                            CHAMPIONS_SHIP.map(item => (
                                isBoxDropDown &&
                                <Container key={item.id} mt={item.margin}>
                                    <BtnChanpionship onClick={() => onActiveChangeChampionsShip(item)}>
                                        <ImgChanpionship src={item.img} />
                                        {item.name}
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }

                        <Container mt='20px'></Container>
                    </BoxDropDown>



                </Container>

                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("maximims")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Máximas</Text>
                        </ButtomMenu>
 {/**                     
                        <ButtomMenu onClick={()=>{handlePage("expressCup")}}>
                            <Text col='#fff' fw='bold' fs='20px'>ExpressCup</Text>
                        </ButtomMenu>
 */}                          
                        <ButtomMenu onClick={()=>{handlePage("hour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Horários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("multihour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Multihorários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("confrontations")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Confrontos</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("ranking")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ranking</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>

                        {/**
                         * 
                             <ButtomMenu onClick={()=>{navigate("/offers")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ofertas</Text>
                        </ButtomMenu>                    * 
                         * 
                         * 
                         */}


                    </Container>

                </SideBarMobile>

                <ContainerMobileOverflow>

                    <Container w='800px'>
                        <Container w="100%" mt='10px'>
                            <ContainerGradient>

                                <BtnStrategySelect onClick={()=>{setIsBoxDropDownStrategy(!isBoxDropDownStrategy)}} ml="50px">
                                    <Text col='#fff'>{onLimitCharacters(strategySelect.name, 15)}</Text>
                                </BtnStrategySelect>

                                <BoxDropDown dp={isBoxDropDownStrategy === true ? '350px' : '0px'} >
                                {
                                    strategyMenu.map((item, index )=> (
                                        isBoxDropDownStrategy &&
                                        <Container key={item.id} mt={"10px"} >
                                            {item.type === "text"
                                            ?
                                            <Container w="100%" display="flex" jc="center" items="center">
                                                <Text fw="bold" col="blue">{item.name}</Text>
                                            </Container>
                                            :

                                            <BtnChanpionship onClick={() => onActionChangeStrategy(item)}>
                                                <Text fw="bold" col="#000">{item.name}</Text>
                                            </BtnChanpionship>
                                        }
                                        </Container>
                                        ))
                                }
                                    <Container mt="20px"></Container>
                                </BoxDropDown> 

                                <Container w="80%" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Resultado das últimas 24 horas</Text>
                                </Container>
                                
                            </ContainerGradient>
                        </Container>

                        <Container w="100%" >
                            <Table>
                                <thead>
                                <Tr>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Posição</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Total de pontos</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Equipe</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Número de jogos</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Vitórias</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Empates</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">Derrotas</Text></Th>
                                    <Th><Text col="#fff" fs="20px"  fw="bold">% de venceu</Text></Th>
                                </Tr>
                                </thead>
                                <tbody>
                                {rankingList.map((item, index)=>{
                                    return (
                                            <Tr key={index}>
                                                <Td><Text col="#fff" fs="20px">{index + 1}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.pontos}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.equipe}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.jogos}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.vitorias}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.empates}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.derrotas}</Text></Td>
                                                <Td><Text col="#fff" fs="20px">{item.percentual_vitorias}</Text></Td>
                                            </Tr>
                                    )
                                })}
                                </tbody>


                            </Table>

                            <Container mt="20px"></Container>

                        </Container>
                    </Container>


                    <Container mt='50px'></Container>
                </ContainerMobileOverflow>



            </ContainerMobileShow>

            {modalOdds === true && 
            <ModalOdds 
            game={gameOddsSelect} 
            date={dateOddsSelect} 
            setModalOdds={setModalOdds}
            url={championsShipSelect.id}
            />}
        
        </GlobalContainer>
    )
}

