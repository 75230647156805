import { useContext, useEffect, useState } from "react";
import { Container, Text } from "../../../componets/styles";
import HourHelp from "../../../helpers/HourHelp";
import { BoxIndicator, BtnCheckIndicator, BtnMinutes, ContainerGradient, ContainerOpacity, ImgChanpionship } from "../style";
import {  API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES } from "../../../config";
import axios from "axios";

import { GiSoccerBall } from "react-icons/gi";
import TooltipComponent from "./tooltip";
import ParamContext from "../../../context/ParamContext";

export default function WorldCup({

    strategyComponent, 
    setReload, 
    showComponent, 
    arrayColorSelect,
    setArrayColorSelect,
    hourFilterComponent

})

{
    const [goalsVertical, setGoalsVertical] = useState([]);
    const [percentualVertical, setPercentualVertical] = useState([]);
    const [buttonsMinutesVertical, setButtonsMinutesVertical] = useState([]);
    const [arrayMinutesColorSelect, setMinutesColorSelect] = useState([]);
    const [barraPercentage, setBarraPercentage] = useState(null)

    const {
        dataWorldCup, setDataWorldCup, allResultsWorldCup, 
        setAllResultsWorldCup, lastKnownIdWorldCup, setLastKnownIdWorldCup,
        dataHourWorldCup, setDataHourWorldCup, percentageWorldCup, setPercentegeWorldCup
    } = useContext(ParamContext);


    useEffect(()=>{

        const generateArray = (startMinute, step) => {
            return Array.from({ length: 20 }, (_, index) => {
              const minute = startMinute + index * step;
              return {
                id: minute / step + 1,
                goals: 0,
                minutes: minute.toString().padStart(2, '0'),
                width: '4.34%'
              };
            });
          };
        
          let newPercentualVertical = [];
          let newButtonsMinutesVertical = [];
        
          newPercentualVertical = generateArray(1, 3);
          newButtonsMinutesVertical = generateArray(1, 3);
        
          setPercentualVertical(newPercentualVertical);
          setButtonsMinutesVertical(newButtonsMinutesVertical);
          setGoalsVertical(newPercentualVertical)

    },[])


    function evaluatePercentage(percentageStr) {
        // Removendo o sinal de porcentagem e convertendo para número
        const percentage = parseFloat(percentageStr.replace('%', ''));
    
        if (percentage <= 50) {
            return "#ef4444";
        } else if (percentage > 50 && percentage <= 60) {
            return "#fb923c";
        } else {
            return "#86efac";
        }
    }


    function calculateGreenPercentage(dataArray, targetMinutes) {

        let totalMinutes = 0;
        let greenMinutes = 0;
      
        dataArray.forEach(hourObj => {
          const matchingMinutes = hourObj.minutes.filter(minuteObj => {
            const minutePart = minuteObj.time.split(":")[1];
            return targetMinutes.some(target => target === minutePart) && minuteObj.id !== null;
          });
      
          const greenMatchingMinutes = matchingMinutes.filter(minuteObj => minuteObj.color === "green");
      
          totalMinutes += matchingMinutes.length;
          greenMinutes += greenMatchingMinutes.length;
        });
      
        const greenPercentage = totalMinutes ? (greenMinutes / totalMinutes) * 100 : 0;
      
        // Arredonda para duas casas decimais e adiciona o símbolo de porcentagem
        const formattedGreenPercentage = greenPercentage.toFixed(2) + '%';
      
        return formattedGreenPercentage;
    }


    function onAddMinutesArray(minutes, item) {

        const arr = [...arrayMinutesColorSelect];  
        const index = arr.indexOf(minutes);
        if (index === -1) {
          arr.push(minutes);
        } else {
          arr.splice(index, 1); 
        }
        setMinutesColorSelect(arr); 
        updateColors(); 
        setBarraPercentage(calculateGreenPercentage(dataHourWorldCup, arr));
    }

    async function updateColors()
    {
        const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataWorldCup, strategyComponent.id, showComponent.id, nextGames.data.data);
        const arr = arrHourGames.slice(0, hourFilterComponent.value);
        const arrData = [...arr]; // Cria uma cópia do array arr
        setDataWorldCup(arrData)
    }

    if(dataHourWorldCup && dataHourWorldCup.length > 0)
    {
        return(
            <>
                <ContainerGradient>
    
                    <Container w="100%" display="flex" jc="space-between" items="center" pl="16px" pr="16px">
    
                        <Container w="auto" display="flex">
                            <Text col="#CFFFC2" fs="20px" fw="bold">GREEN: {percentageWorldCup.green}</Text>
                            <Text col="#FF0000" fs="20px" fw="bold" ml="20px" mr="150px">RED: {percentageWorldCup.red}</Text>
                        </Container>
    
                        <Container w="auto" display="flex" jc="ceter" items="center">
                            <ImgChanpionship w="50px" h="50px" src="./images/copa-do-mundo.png"/>
                            <Text col="#fff" fw="bold">Copa do mundo</Text>
                        </Container>
    
                    </Container>
    
                </ContainerGradient>
    
                <Container w="100%" pl="20px" pr="20px"  display="flex" >
    
                    <Container  w={"4.34%"}>
                        <Container w="100%" display="flex" jc="center" items="center">
                            <Text col="#fff">G</Text>
                        </Container>
                    </Container>
                            
                    {goalsVertical.map(item=>{
                        return(
                            <Container key={item.id} w={item.width}>
                                <Container w="100%" display="flex" jc="center" items="center">
                                    <Text col="#fff">{HourHelp.getTotalGoalsForMinute(dataHourWorldCup, item.minutes)}</Text>
                                </Container>
                            </Container>
                        )
                    })}
    
                    <Container  w={"8.68%"}>
                        <Container w="100%" display="flex" jc="center" items="center">
                            <Text col="#fff"></Text>
                        </Container>
                    </Container>
    
                </Container>
                <Container pos={"relative"} >
                        <ContainerOpacity>
    
                            <Container w="100%" pb="20px" pt="20px"  display="flex" >
    
                            <Container w={"4.34%"} ></Container>
    
                            {percentualVertical.map(item=>{
                                    return(
                                            <Container key={item.id} w={item.width} >
                                                <Container w="100%">
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text  fs="14px"col={evaluatePercentage(HourHelp.analyzeGreenForMinute(dataHourWorldCup, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(dataHourWorldCup, item.minutes).greenCount}</Text>
                                                    </Container>
                                                    <Container  w="100%" display="flex" jc="center" items="center" mt="5px">
                                                        <Text  fs="12px" col={evaluatePercentage(HourHelp.analyzeGreenForMinute(dataHourWorldCup, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(dataHourWorldCup, item.minutes).greenPercentage}</Text>
                                                    </Container>
                                                </Container>
                                            </Container>
                                    )
                                })}
                            </Container>
                            {arrayMinutesColorSelect.length > 0
                                &&
                                <Container h="30px" w="100%" mb="5px">
                                    <Container h="100%" w={barraPercentage} bg="#ccc" display="flex" jc="center" items="center">
                                        <Text  fw="bold" col="green">{`${barraPercentage} ${strategyComponent.name}`}</Text>
                                    </Container>
                                </Container>
                            }
    
    
                            <Container  display="flex">
    
                                <Container w="4.34%" display="flex" jc="center" items="center">
                                    <Text col="#FFFF">Hora</Text>
                                </Container>
    
                                {buttonsMinutesVertical.map(item=>{
                                    return(
    
                                        <Container key={item.id} w={item.width} display="flex" jc="center" items="center">
                                            <BtnMinutes onClick={()=>{onAddMinutesArray(item.minutes, item)}}>{item.minutes}</BtnMinutes>
                                        </Container>
                                    )
                                })}
    
                                <Container w="4.34%" display="flex" jc="center" items="center">
                                    <Text col="#FFFF">Dados</Text>
                                </Container>
    
                                <Container w="4.34%" display="flex" jc="center" items="center">
                                    <Text col="#FFFF"><GiSoccerBall/></Text>
                                </Container>
                        
                            </Container>
    
                            {dataHourWorldCup.map((hour, index)=>{
    
                                return( 
                                    <Container key={index} display="flex" >
    
                                        <BtnCheckIndicator w={"4.34%"} bg="#707070">
                                            {/*hour.marked ? <BsCheckSquare color='#fff'/> : <BsSquareFill color='#fff'/>*/}
                                            <Text ml="5px" col='#fff'>{hour.hour}</Text>
                                        </BtnCheckIndicator>
    
                                        {hour.minutes.map((item,index)=>{
                                            return(
                                              
                                                <TooltipComponent  key={index}
                                                     item={item} 
                                                     arrayColorSelect={arrayColorSelect} 
                                                     setArrayColorSelect={setArrayColorSelect}
                                                     updateColors={updateColors}
                                                     arrayMinutesColorSelect={arrayMinutesColorSelect}
                                                     setMinutesColorSelect={setMinutesColorSelect}
                                                />
                                              
                                            )
                                        })}
                                        
    
                                        <BoxIndicator bg={ "#737373"} w="4.34%">
                                            <Text fs="14px" ml="5px" col={evaluatePercentage(hour.greenPercentage)} fw="bold">{hour.greenPercentage}</Text>
                                        </BoxIndicator>
    
                                        <BoxIndicator  bg={"#a3a3a3"} w="4.34%">
                                            <Text fs="14px" ml="5px" col='#FFE500' fw="bold">{hour.totalGoals}</Text>
                                        </BoxIndicator>
            
                                    </Container>
                                )
                            })}
    
    
    
                        </ContainerOpacity>
                    </Container>
            </>
        )
    }else{
        return (
        <Container w="100%" display="flex" jc="center" items="center">
            <ImgChanpionship w="50px" h="50px" src="./images/copa-do-mundo.png"/>
            <Text col="#FFFF">Carregando...</Text>
        </Container>
        )
    }




}