
import { useContext, useEffect, useState } from 'react';
import { API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES, API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES, API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES, API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES } from '../../config';
import {ButtomMenu, ButtonSide, ContainerMobileHide, ContainerMobileOverflow, ContainerMobileShow, GlobalContainer, Header, HeaderMobile, LogoMobile, SideBarMobile, Sidebar}  from './style';
import { BsList, BsGraphUp, BsPlayCircle, BsXLg} from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, ImageLogo, Text } from '../../componets/styles';
import { MainContainer } from './style';
import { BoxChampionship } from './style';
import { BoxDropDown } from './style';
import { BtnChanpionship } from './style';
import Reload from '../../componets/reload';
import ModalOdds from '../Modals/ModalOdds';
import HourHelp from '../../helpers/HourHelp';
import WorldCup from './containers/WorldCup';
import EuroCup from './containers/EuroCup';
import SouthAmericanSuperLeague from './containers/SouthAmericanSuperLeague';
import PremierLeague from './containers/PremierLeague';
import AuthContext from '../../context/AuthContext';
import { formatarData } from '../../share/functions';
import axios from 'axios';
import WorldCupMobile from './containers/WorldCupMobile';
import EuroCupMobile from './containers/EuroCupMobile';
import { CHAMPIONS_SHIP, HOUR_FILTER, SHOW, STRATEGY } from './constants';
import { calculetePercentege, getDataWorldCup, getDataEuroCup, getDataSouthCup, getDataPremierLeague } from './functions';
import ParamContext from '../../context/ParamContext';
import SouthAmericanSuperLeagueMobile from './containers/SouthAmericanSuperLeagueMobile';
import PremierLeagueMobile from './containers/PremierLeagueMobile.js';



export default function MultiHour({handlePage})
{

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const {
        //Copa mundo
        allResultsWorldCup, setAllResultsWorldCup, dataWorldCup, setDataWorldCup, 
        lastKnownIdWorldCup, setLastKnownIdWorldCup, dataHourWorldCup, setDataHourWorldCup,
        percentageWorldCup, setPercentegeWorldCup, nextGamesWorldCup, setNextGamesWorldCup,
        //Euro Copa
        dataEuroCup, setDataEuroCup, allResultsEuroCup, 
        setAllResultsEuroCup, lastKnownIdEuroCup, setLastKnownIdEuroCup,
        dataHourEuroCup, setDataHourEuroCup, percentageEuroCup, setPercentegeEuroCup,
        nextGamesEuroCup, setNextGamesEuroCup,
        //Super liga
        dataSuperLeague, setDataSuperLeague, dataHourSuperLeague, setDataHourSuperLeague,
        allResultsSuperLeague, setAllResultsSuperLeague, lastKnownIdSuperLeague, 
        setLastKnownIdSuperLeague, percentageSuperLeague, setPercentegeSuperLeague, nextGamesSuperLeague, setNextGamesSuperLeague,
        //Premier League
        dataPremierLeague, setDataPremierLeague, dataHourPremierLeague, setDataHourPremierLeague,
        allResultsPremierLeague, setAllResultsPremierLeague, lastKnownIdPremierLeague, 
        setLastKnownIdPremierLeague, percentagePremierLeague, setPercentegePremierLeague,
        nextGamesPremierLeague, setNextGamesPremierLeague

    } = useContext(ParamContext);

    const [isSideOpen, setIsSideOpen] = useState(false);
    const [isBoxDropDownChanpionsShip, setIsBoxDropDownChanpionsShip] = useState(false);
    const [isBoxDropDownShow, setIsBoxDropShow] = useState(false);
    const [isBoxDropDownStrategy, setIsBoxDropStrategy] = useState(false);
    const [isBoxDropDownHourFilter, setIsBoxDropDownHourFilter] = useState(false);
    const [chanpionsShipComponent, setChanpionsShipComponent] = useState({id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px', value:"Copa do Mundo"})
    const [showComponent, setShowComponent] = useState({id:1, name:"Resultado FT"});
    const [strategyComponent, setStrategyComponent] = useState({id:50, name:"Sim", type:"button", strategy:"Ambas Marcam"});
    const [hourFilterComponent, setHourFilterCoponent] = useState({id:2, name: "12h", value:12})
    const [allResults, setAllResults] = useState([]);
    const [reload, setReload] = useState(false);
    const [modalOdds, setModalOdds] = useState(false);
    const [gameOddsSelect, setGameOddsSelect] = useState(null);
    const [dateOddsSelect, setDateOddsSelect] = useState(null);
    const [sideBarMobile, setSideBarMobile] = useState(false);
    const [selectTooltip, setSelectTooltip] = useState(null);
    const [arrayColorSelect, setArrayColorSelect] = useState([{}]);
    const [lastKnownId, setLastKnownId] = useState("first");

    const navigate = useNavigate();
/*
    async function handleShow(item)
    {
        setShowComponent(item);

        setIsBoxDropShow(false);

        const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(allResultsWorldCup, strategyComponent.id, item.id, nextGames.data.data);
    }
*/
    async function handleStrategy(item)
    {
        setStrategyComponent(item)
        handleDataWorldCupByEstrategy(item.id)
        handleDataEuroCupByEstrategy(item.id)
        handleDataSuperLigaByEstrategy(item.id)
        handleDataPremierLeagueByEstrategy(item.id)
        setIsBoxDropStrategy(false)
    }

    function handleHourFilter(item)
    {
        setHourFilterCoponent(item)
        handleDataWorldCupByHour(item)
        handleDataEuroCupByHour(item)
        handleDataSuperLigaCupByHour(item)
        handleDataPremierLeagueByHour(item)
        setIsBoxDropDownHourFilter(false)
    }


    function visibleDropDownShow() {
        setIsBoxDropShow(prev => !prev);
    }

    function visibleDropDownStrategy() {
        setIsBoxDropStrategy(prev => !prev);
    }


    function visibleDropDownHourFilter() {
        setIsBoxDropDownHourFilter(prev => !prev);
    }

    useEffect(() => {
        // Executa imediatamente ao montar o componente
        handleDataWorldCup();
        handleDataEuroCup();
        handleDataSuperLiga();
        handleDataPremierLeague();
      
        // Configura o intervalo
        const interval = setInterval(() => {
          handleDataWorldCup();
          handleDataEuroCup();
          handleDataSuperLiga();
          handleDataPremierLeague();
        }, 60000); // 60000ms = 60 segundos
      
        // Limpa o intervalo quando o componente for desmontado
        return () => clearInterval(interval);
      }, []);

    function limitCharacters(input, maxLength) {
        if (input.length <= maxLength) {
          return input;
        }
        return input.substring(0, maxLength) + "...";
    }

    //Copa Mundo


    async function handleDataWorldCup()
    {
        const response = await getDataWorldCup(lastKnownIdWorldCup);
        const rd = response.gamesResponse.data
        const ngs = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);
        setNextGamesWorldCup(ngs.data.data);
        const arrHourGames = HourHelp.arrHour(rd, strategyComponent.id, showComponent.id, ngs.data.data);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeWorldCup({
            ...percentageWorldCup,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        setDataWorldCup(rd)
        setLastKnownIdWorldCup(rd[0].id);
        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);
        setDataHourWorldCup(dataSlice)
    }

    
    async function handleDataWorldCupByEstrategy(strategyId)
    {
        // setDataHourWorldCup([])

        //const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(dataWorldCup, strategyId, showComponent.id, nextGamesWorldCup);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeWorldCup({
            ...percentageWorldCup,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);
        setDataHourWorldCup(dataSlice)
    }

    async function handleDataWorldCupByHour(hour)
    {
        //setDataHourWorldCup([])

        //const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataWorldCup, strategyComponent.id, showComponent.id, nextGamesWorldCup);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeWorldCup({
            ...percentageWorldCup,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hour.value);
        setDataHourWorldCup(dataSlice)
    }

    //Euro copa

    async function handleDataEuroCup()
    {
        const response = await getDataEuroCup(lastKnownIdEuroCup);
        const rd = response.gamesResponse.data
        const ngs = await axios.get(`${ API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES}`);

        setNextGamesEuroCup(ngs.data.data);

        const arrHourGames = HourHelp.arrHour(rd, strategyComponent.id, showComponent.id, ngs.data.data);

        const calcPercentege = calculetePercentege(arrHourGames);

        setPercentegeEuroCup({
            ...percentageEuroCup,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })

        setDataEuroCup(rd)

        setLastKnownIdEuroCup(rd[0].id);

        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);

        setDataHourEuroCup(dataSlice)
    }

    
    async function handleDataEuroCupByEstrategy(strategyId)
    {
        //setDataHourEuroCup([])
        //const nextGames = await axios.get(`${ API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataEuroCup, strategyId, showComponent.id, nextGamesEuroCup);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeEuroCup({
            ...percentageEuroCup,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);
        setDataHourEuroCup(dataSlice)
    }

    async function handleDataEuroCupByHour(hour)
    {
       // setDataHourEuroCup([])
       // const nextGames = await axios.get(`${ API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataEuroCup, strategyComponent.id, showComponent.id, nextGamesEuroCup);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeEuroCup({
            ...percentageEuroCup,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hour.value);
        setDataHourEuroCup(dataSlice)
    }


     //Super Liga Super Americana

    async function handleDataSuperLiga()
    {
       // setDataHourSuperLeague([])
        const response = await getDataSouthCup(lastKnownIdSuperLeague);
        const rd = response.gamesResponse.data
        const ngs = await axios.get(`${ API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES}`);
        setNextGamesSuperLeague(ngs.data.data);
        const arrHourGames = HourHelp.arrHour(rd, strategyComponent.id, showComponent.id, ngs.data.data);

        const calcPercentege = calculetePercentege(arrHourGames);

        setPercentegeSuperLeague({
            ...percentageSuperLeague,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })

        setDataSuperLeague(rd)

        setLastKnownIdSuperLeague(rd[0].id);

        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);

        setDataHourSuperLeague(dataSlice)
    }

    
    async function handleDataSuperLigaByEstrategy(strategyId)
    {
       // setDataHourSuperLeague([])
       // const nextGames = await axios.get(`${ API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataSuperLeague, strategyId, showComponent.id, nextGamesSuperLeague);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeSuperLeague({
            ...percentageSuperLeague,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);
        setDataHourSuperLeague(dataSlice)
    }

    async function handleDataSuperLigaCupByHour(hour)
    {
       // setDataHourSuperLeague([])
       // const nextGames = await axios.get(`${ API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataSuperLeague, strategyComponent.id, showComponent.id, nextGamesSuperLeague);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegeSuperLeague({
            ...percentageSuperLeague,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hour.value);
        setDataHourSuperLeague(dataSlice)
    }


    //Premier League

    async function handleDataPremierLeague()
    {

        const response = await getDataPremierLeague(lastKnownIdPremierLeague);
        const rd = response.gamesResponse.data
        const ngs = await axios.get(`${ API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES}`);
        setNextGamesPremierLeague(ngs.data.data);
        const arrHourGames = HourHelp.arrHour(rd, strategyComponent.id, showComponent.id, ngs.data.data);

        const calcPercentege = calculetePercentege(arrHourGames);

        setPercentegePremierLeague({
            ...percentagePremierLeague,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })

        setDataPremierLeague(rd)

        setLastKnownIdPremierLeague(rd[0].id);

        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);

        setDataHourPremierLeague(dataSlice)
    }

    async function handleDataPremierLeagueByHour(hour)
    {
       // setDataHourPremierLeague([])
       // const nextGames = await axios.get(`${ API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataPremierLeague, strategyComponent.id, showComponent.id, nextGamesPremierLeague);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegePremierLeague({
            ...percentagePremierLeague,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hour.value);
        setDataHourPremierLeague(dataSlice)
    }

    async function handleDataPremierLeagueByEstrategy(strategyId)
    {
       // setDataHourPremierLeague([])
       // const nextGames = await axios.get(`${ API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES}`);
        const arrHourGames = HourHelp.arrHour(dataPremierLeague, strategyId, showComponent.id, nextGamesPremierLeague);
        const calcPercentege = calculetePercentege(arrHourGames);
        setPercentegePremierLeague({
            ...percentagePremierLeague,
            green: calcPercentege.greenPercentageTotal,
            red: calcPercentege.redPercentageTotal
        })
        const dataSlice = arrHourGames.slice(0, hourFilterComponent.value);
        setDataHourPremierLeague(dataSlice)
    }

    return(
        <GlobalContainer>
           {reload === true && <Reload bg='#000'/>} 
           <ContainerMobileHide>

           <Header>

            <Container display={'flex'}  items="center"  w="300px" h="100%" ml="50px">
                <ImageLogo src='./images/logo.png'/>
            </Container>

            <Container w='auto' display='flex' mr="20px" items='center'>

                    <Container w='auto' display='flex' mr="20px" >
                        <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{`${formatarData(user.validation)}`}</Text>
                    </Container>


                    <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                        <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                    </ButtonStyled>
                </Container>

            </Header>



            <Sidebar open={isSideOpen}>

                <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                    <BsList col='#fff' size={45}/>
                    {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                </ButtonSide>

{/** 
                        <ButtonSide open={isSideOpen}  mt="50px" onClick={()=>{navigate("/home")}}>
                            <BsSpeedometer2 col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                            
                        </ButtonSide>
*/}

                <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/analysis")}}>
                    <BsGraphUp col='#fff' size={33}/>
                    {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                </ButtonSide>

                <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/videos')}}>
                    <BsPlayCircle  col='#fff' size={35}/>
                    {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                </ButtonSide>
{/*
                <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/robos')}}>
                    <BsTags  col='#fff' size={35}/>
                    {isSideOpen && <Text col="#fff" fw="bold" ml="10px" >Robôs</Text>}
                </ButtonSide>
* */}
            </Sidebar>

            <MainContainer >

            <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display={"flex"} jc="space-between">
                        
                        <ButtonStyled w="16.2%" bg='#15803d' onClick={()=>{handlePage("maximims")}}>
                            <Text col="#fff" fw="bold" fs="25px">MÁXIMAS</Text>
                        </ButtonStyled>
  {/** 
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("expressCup")}}>
                            <Text col="#fff" fw="bold" fs="25px">EXPRESS CUP</Text>
                        </ButtonStyled>
  */}
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("hour")}}>
                            <Text col="#fff" fw="bold" fs="25px">HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("multihour")}}>
                            <Text col="#fff" fw="bold" fs="25px">MULTI HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("confrontations")}}>
                            <Text col="#fff" fw="bold" fs="25px">CONFRONTOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%" onClick={()=>{handlePage("ranking")}}>
                            <Text col="#fff" fw="bold" fs="25px">RANKING</Text>
                        </ButtonStyled>                     

                    </Container>

                    <Container w="98%" display="flex" pl="20px" pr="20px" pb="20px" pt="20px">

                        <Container w="70%" display="flex" items="center">

                            {/**
                             * 
                            <Text fs="25px" col="#fff" fw="bold" ml="10px"> Mostrar:</Text>

                            <BoxChampionship onClick={visibleDropDownShow} ml="30px">
                                <Container display={'flex'}  items="center" jc="center">
                                    <Text  fs="20px" col='#fff'>{limitCharacters(showComponent.name, 15)}</Text>
                                </Container>
                            </BoxChampionship>

                            <BoxDropDown dp={isBoxDropDownShow === true ? '300px' : '0px'}>
                                    {
                                        SHOW.map(item => (

                                            isBoxDropDownShow &&

                                            <Container key={item.id} mt={"10px"}>
                                                {item.type === "text"
                                                ?
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fw="bold" col="blue">{item.name}</Text>
                                                    </Container>
                                                :

                                                    <BtnChanpionship onClick={() => handleShow(item)} disabled={false}>
                                                        <Text fw="bold" col="#000">{item.name}</Text>
                                                    </BtnChanpionship>
                                                }
                                                
                                            </Container>
                                        ))
                                    }
                                    <Container mt="20px"></Container>
                                </BoxDropDown>
                             * 
                            */}



                                <Text fs="25px" col="#fff" fw="bold" ml="10px"> Estratégia:</Text>

                                <BoxChampionship onClick={visibleDropDownStrategy} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text fs="20px" col='#fff'>{limitCharacters(`${strategyComponent.strategy ? strategyComponent.strategy : ''} (${strategyComponent.name})`, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown dp={isBoxDropDownStrategy === true ? '300px' : '0px'}>
                                    {
                                        STRATEGY.map((item, index )=> (

                                            isBoxDropDownStrategy &&
                                            <Container key={item.id} mt={"10px"} >
                                                {item.type === "text"
                                                ?
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fw="bold" col="blue">{item.name}</Text>
                                                    </Container>
                                                :

                                                    <BtnChanpionship onClick={() => handleStrategy(item)}>
                                                        <Text fw="bold" col="#000">{item.name}</Text>
                                                    </BtnChanpionship>
                                                }
                                            </Container>
                                        ))
                                    }
                                    <Container mt="20px"></Container>
                                </BoxDropDown>

                                <Text fs="25px" col="#fff" fw="bold" ml="10px"> Últimas horas:</Text>

                                <BoxChampionship onClick={visibleDropDownHourFilter} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text  fs="20px" col='#fff'>{limitCharacters(hourFilterComponent.name, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown h="320px" dp={isBoxDropDownHourFilter === true ? '300px' : '0px'}>
                                    {
                                        HOUR_FILTER.map(item => (
                                            isBoxDropDownHourFilter &&
                                            <Container key={item.id} mt={"10px"}>
                                                <BtnChanpionship onClick={() => handleHourFilter(item)}>
                                                    <Text fs="20px" fw="bold">{item.name}</Text>
                                                </BtnChanpionship>
                                            </Container>
                                        ))
                                    }
                                    <Container h="20px"></Container>
                                </BoxDropDown>

                            </Container>
                    </Container>

        
                    <Container w="98%" display="flex" jc="space-between" items="center" pl="20px" pr="20px" pb="20px" pt="20px">
                        
                        <Container w="49.5%">

                            <WorldCup 

                                hourFilterComponent={hourFilterComponent}
                                strategyComponent={strategyComponent}
                                setReload={setReload}
                                showComponent={showComponent}
                                arrayColorSelect={arrayColorSelect}
                                setArrayColorSelect={setArrayColorSelect}

                            />

                        </Container>
                    
                        <Container w="49.5%">

                            <EuroCup

                                hourFilterComponent={hourFilterComponent}
                                strategyComponent={strategyComponent}
                                setReload={setReload}
                                showComponent={showComponent}
                                arrayColorSelect={arrayColorSelect}
                                setArrayColorSelect={setArrayColorSelect}  

                            />

                        </Container>
                
                    </Container>

                    <Container w="98%" display="flex" jc="space-between" items="center" pl="20px" pr="20px" pb="20px" pt="20px">
                        
                        <Container w="49.5%">

                                <SouthAmericanSuperLeague
                                    hourFilterComponent={hourFilterComponent}
                                    strategyComponent={strategyComponent}
                                    setReload={setReload}
                                    showComponent={showComponent}  
                                    arrayColorSelect={arrayColorSelect}
                                    setArrayColorSelect={setArrayColorSelect}
                                />

                            </Container>

                            <Container w="49.5%">
                                <PremierLeague
                                    hourFilterComponent={hourFilterComponent}
                                    strategyComponent={strategyComponent}
                                    setReload={setReload}
                                    showComponent={showComponent}  
                                    arrayColorSelect={arrayColorSelect}
                                    setArrayColorSelect={setArrayColorSelect}                       
                                />
                            </Container>                            
                    </Container>



                    <Container mt='100px'></Container>
        
                </MainContainer>
                
            </ContainerMobileHide>

            {/**TEMPLATE MOBILE */}
            <ContainerMobileShow>

                <HeaderMobile>

                    <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                        <BsList color='#fff' size={30}/>
                    </ButtonStyled>

                    <Container w='auto' display='flex' >
                        <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                    </Container>

                    <LogoMobile src='./images/logo.png'/>

                </HeaderMobile>

                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("maximims")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Máximas</Text>
                        </ButtomMenu>
  {/**                    
                        <ButtomMenu onClick={()=>{handlePage("expressCup")}}>
                            <Text col='#fff' fw='bold' fs='20px'>ExpressCup</Text>
                        </ButtomMenu>
 */}                          
                        <ButtomMenu onClick={()=>{handlePage("hour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Horários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("multihour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Multihorários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("confrontations")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Confrontos</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("ranking")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ranking</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>
                        {/**
                         *                         <ButtomMenu onClick={()=>{navigate("/robos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Robôs</Text>
                        </ButtomMenu>
                         * 
                         */}

                    </Container>

                </SideBarMobile>

                <ContainerMobileOverflow>

                    <Container mt='20px'></Container>

                    <Container w='100%'>

                    <Container w="100%">

                        <Container mt='0px' w="100%" display="block" items="center">
{/** 
                            <BoxChampionship onClick={visibleDropDownShow} ml="30px">
                                <Container display={'flex'}  items="center" jc="center">
                                    <Text  fs="20px" col='#fff'>{limitCharacters(showComponent.name, 15)}</Text>
                                </Container>
                            </BoxChampionship>

                            <BoxDropDown dp={isBoxDropDownShow === true ? '300px' : '0px'}>
                                    {
                                        SHOW.map(item => (

                                            isBoxDropDownShow &&

                                            <Container key={item.id} mt={"10px"}>
                                                {item.type === "text"
                                                ?
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fw="bold" col="blue">{item.name}</Text>
                                                    </Container>
                                                :

                                                    <BtnChanpionship onClick={() => handleShow(item)} disabled={false}>
                                                        <Text fw="bold" col="#000">{item.name}</Text>
                                                    </BtnChanpionship>
                                                }
                                                
                                            </Container>
                                        ))
                                    }
                                    <Container mt="20px"></Container>
                                </BoxDropDown>
*/}
                                <BoxChampionship onClick={visibleDropDownStrategy} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text fs="20px" col='#fff'>{limitCharacters(`${strategyComponent.strategy ? strategyComponent.strategy : ''} (${strategyComponent.name})`, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown dp={isBoxDropDownStrategy === true ? '300px' : '0px'}>
                                    {
                                        STRATEGY.map((item, index )=> (

                                            isBoxDropDownStrategy &&
                                            <Container key={item.id} mt={"10px"} >
                                                {item.type === "text"
                                                ?
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fw="bold" col="blue">{item.name}</Text>
                                                    </Container>
                                                :

                                                    <BtnChanpionship onClick={() => handleStrategy(item)}>
                                                        <Text fw="bold" col="#000">{item.name}</Text>
                                                    </BtnChanpionship>
                                                }
                                            </Container>
                                        ))
                                    }
                                    <Container mt="20px"></Container>
                                </BoxDropDown>

                                <BoxChampionship onClick={visibleDropDownHourFilter} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text  fs="20px" col='#fff'>{limitCharacters(hourFilterComponent.name, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown h="320px" dp={isBoxDropDownHourFilter === true ? '300px' : '0px'}>
                                    {
                                        HOUR_FILTER.map(item => (
                                            isBoxDropDownHourFilter &&
                                            <Container key={item.id} mt={"10px"}>
                                                <BtnChanpionship onClick={() => handleHourFilter(item)}>
                                                    <Text fs="20px" fw="bold">{item.name}</Text>
                                                </BtnChanpionship>
                                            </Container>
                                        ))
                                    }
                                    <Container h="20px"></Container>
                                </BoxDropDown>

                            </Container>
                        </Container>  

                        <WorldCupMobile
                            hourFilterComponent={hourFilterComponent}
                            strategyComponent={strategyComponent}
                            setReload={setReload}
                            showComponent={showComponent}
                            arrayColorSelect={arrayColorSelect}
                            setArrayColorSelect={setArrayColorSelect}
                        />

                        <EuroCupMobile
                            hourFilterComponent={hourFilterComponent}
                            strategyComponent={strategyComponent}
                            setReload={setReload}
                            showComponent={showComponent}
                            arrayColorSelect={arrayColorSelect}
                            setArrayColorSelect={setArrayColorSelect}                   
                        />

                        <SouthAmericanSuperLeagueMobile
                            hourFilterComponent={hourFilterComponent}
                            strategyComponent={strategyComponent}
                            setReload={setReload}
                            showComponent={showComponent}
                            arrayColorSelect={arrayColorSelect}
                            setArrayColorSelect={setArrayColorSelect}                   
                        />

                        <PremierLeagueMobile
                            hourFilterComponent={hourFilterComponent}
                            strategyComponent={strategyComponent}
                            setReload={setReload}
                            showComponent={showComponent}
                            arrayColorSelect={arrayColorSelect}
                            setArrayColorSelect={setArrayColorSelect}                   
                        />
                         <Container mt='20px'></Container>

                        {/**
                        <SouthAmericanSuperLeagueMobile
                            hourFilterComponent={hourFilterComponent}
                            strategyComponent={strategyComponent}
                            setReload={setReload}
                            showComponent={showComponent}  
                        /> 
                         <Container mt='20px'></Container> 
                          
                        <PremierLeagueMobile
                            hourFilterComponent={hourFilterComponent}
                            strategyComponent={strategyComponent}
                            setReload={setReload}
                            showComponent={showComponent}                         
                        />  
                        
                         */}

                         <Container mt='20px'></Container>
                   
                    </Container>

                    <Container mt='50px'></Container>

                </ContainerMobileOverflow>

            </ContainerMobileShow>

            {modalOdds === true && 
            <ModalOdds 
                game={gameOddsSelect} 
                date={dateOddsSelect} 
                setModalOdds={setModalOdds}
                url={chanpionsShipComponent.id}
            />}
        
        </GlobalContainer>
    )
}

