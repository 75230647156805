import { useState } from "react";
import ParamContext from "./ParamContext";

const ParamProvider = ({ children }) => {

    const [strategy, setStrategy] = useState(6);
    const [hourData, setHourData] = useState(23);
    const [showData, setShowData] = useState(1);
    const [selectedScores, setSelectedScores] = useState([]);




    //Copa do mundo
    const [nextGamesWorldCup, setNextGamesWorldCup] = useState([]);
    const [dataWorldCup, setDataWorldCup] = useState([]);
    const [dataHourWorldCup, setDataHourWorldCup] = useState([]);
    const [allResultsWorldCup, setAllResultsWorldCup] = useState([]);
    const [lastKnownIdWorldCup, setLastKnownIdWorldCup] = useState("first");
    const [percentageWorldCup, setPercentegeWorldCup] = useState({
      red: '0,00%',
      green: '0,00%'
    })

    //Euro copa
    const [nextGamesEuroCup, setNextGamesEuroCup] = useState([]);
    const [dataEuroCup, setDataEuroCup] = useState([]);
    const [dataHourEuroCup, setDataHourEuroCup] = useState([]);
    const [allResultsEuroCup, setAllResultsEuroCup] = useState([]);
    const [lastKnownIdEuroCup, setLastKnownIdEuroCup] = useState("first");
    const [percentageEuroCup, setPercentegeEuroCup] = useState({
      red: '0,00%',
      green: '0,00%'
    })

    //Super Liga Super Americana
    
    const [nextGamesSuperLeague, setNextGamesSuperLeague] = useState([]);
    const [dataSuperLeague, setDataSuperLeague] = useState([]);
    const [dataHourSuperLeague, setDataHourSuperLeague] = useState([]);
    const [allResultsSuperLeague, setAllResultsSuperLeague] = useState([]);
    const [lastKnownIdSuperLeague, setLastKnownIdSuperLeague] = useState("first");
    const [percentageSuperLeague, setPercentegeSuperLeague] = useState({
      red: '0,00%',
      green: '0,00%'
    })

    //Premier League Inglesa

    const [nextGamesPremierLeague, setNextGamesPremierLeague] = useState([]);
    const [dataPremierLeague, setDataPremierLeague] = useState([]);
    const [dataHourPremierLeague, setDataHourPremierLeague] = useState([]);
    const [allResultsPremierLeague, setAllResultsPremierLeague] = useState([]);
    const [lastKnownIdPremierLeague, setLastKnownIdPremierLeague] = useState("first");
    const [percentagePremierLeague, setPercentegePremierLeague] = useState({
      red: '0,00%',
      green: '0,00%'
    })

    return (
        <ParamContext.Provider value={{ 
            strategy, setStrategy, hourData, setHourData, showData, 
            setShowData, selectedScores, setSelectedScores, 
            dataWorldCup, setDataWorldCup, allResultsWorldCup, setAllResultsWorldCup,
            lastKnownIdWorldCup, setLastKnownIdWorldCup, dataHourWorldCup, setDataHourWorldCup,
            percentageWorldCup, setPercentegeWorldCup, dataEuroCup, setDataEuroCup, 
            dataHourEuroCup, setDataHourEuroCup,allResultsEuroCup, setAllResultsEuroCup, 
            lastKnownIdEuroCup, setLastKnownIdEuroCup, percentageEuroCup, setPercentegeEuroCup,
            dataSuperLeague, setDataSuperLeague, dataHourSuperLeague, setDataHourSuperLeague,
            allResultsSuperLeague, setAllResultsSuperLeague, lastKnownIdSuperLeague, 
            setLastKnownIdSuperLeague, percentageSuperLeague, setPercentegeSuperLeague,
            dataPremierLeague, setDataPremierLeague, dataHourPremierLeague, setDataHourPremierLeague,
            allResultsPremierLeague, setAllResultsPremierLeague, lastKnownIdPremierLeague, 
            setLastKnownIdPremierLeague, percentagePremierLeague, setPercentegePremierLeague, nextGamesWorldCup, setNextGamesWorldCup,
            nextGamesEuroCup, setNextGamesEuroCup, nextGamesSuperLeague, setNextGamesSuperLeague, nextGamesPremierLeague, setNextGamesPremierLeague
        }}>
          {children}
        </ParamContext.Provider>
      );

}

export default ParamProvider;